import { useState, } from "react";
import {mfrtIkToBars} from "../utils/mftr"

const IkDocBars = () => {

    const [fileName, setFileName] = useState("")
    const handleChange = (event) => {
        let file = event.target.files[0];
        setFileName(file);
    };
    const handleProcessingFile = (event) => { 
        mfrtIkToBars(fileName);
        setFileName("")
    };
    return (
        <>
            <h2>Обработка IK файла для загрузки в Барс</h2>
        <div>
            Выберите zip файл
        </div>
        <div>
            <form>
                <label className="input-file">
                    <span className="input-file-text" type="text">{fileName.name}</span>
                    <input type="file" accept=".zip" onChange={handleChange} />
                    <span className="input-file-btn br-left">Обзор</span>
                </label>
            </form>
        </div>
        <div>
        <button type="submit"  onClick={handleProcessingFile}>Обработать</button>
        </div>    

        </>
    );
}

export default IkDocBars;