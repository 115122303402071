import { useState, } from "react";
import {mfrtStatmenRtf} from "../utils/mftr"

const PrintStatement = () => {
    const [fileName, setFileName] = useState("")
    const handleChange = (event) => {
        //setFile(event.target.files[0])*/
        let file = event.target.files[0];
        setFileName(file);
    };
    const handleProcessingFile = (event) => {
        //setFile(event.target.files[0])*/  
        mfrtStatmenRtf(fileName);
        setFileName("")
    };



    return (
        <>
        <h2>Обработка печатной формы</h2>
        <div>
            Выберите zip файл выписки
        </div>
        <div>
            <form>
                <label className="input-file">
                    <span className="input-file-text" type="text">{fileName.name}</span>
                    <input type="file" accept=".zip" onChange={handleChange} />
                    <span className="input-file-btn br-left">Обзор</span>
                </label>
            </form>
        </div>
        <div>
        <button type="submit"  onClick={handleProcessingFile}>Обработать</button>
        </div>            
        </>
    );
}

export default PrintStatement;