import { useState, } from "react";
import {mfrtIkToAzk} from "../utils/mftr"
const IkDocAzk = () => {
    const [fileNameZip, setFileNameZip] = useState("")
    const [fileNameExcel, setFileNameExcel] = useState("")
    const handleChangeZip = (event) => {
        let file = event.target.files[0];
        setFileNameZip(file);
    };
    const handleChangeExcel = (event) => {
        let file = event.target.files[0];
        setFileNameExcel(file);
    };
    const handleProcessingFile = (event) => { 
        mfrtIkToAzk(fileNameExcel,fileNameZip);
        setFileNameZip("");
        setFileNameExcel("")
    };

    return (
        <>
            <h2>Обработка IK файла для загрузки в АЦК</h2>
            <div>
                Выберите zip файл выписки
            </div>
            <div>
                <form>
                    <label className="input-file">
                        <span className="input-file-text" type="text">{fileNameZip.name}</span>
                        <input type="file" accept=".zip" onChange={handleChangeZip} />
                        <span className="input-file-btn br-left">Обзор</span>
                    </label>
                </form>
            </div>
            <div>
                Выберите Excel файл выписки
            </div>
            <div>
                <form>
                    <label className="input-file">
                        <span className="input-file-text" type="text">{fileNameExcel.name}</span>
                        <input type="file" accept=".xls,.xlsx" onChange={handleChangeExcel} />
                        <span className="input-file-btn br-left">Обзор</span>
                    </label>
                </form>
            </div>
            <div>
                <button type="submit"  onClick={handleProcessingFile}>Обработать</button>
            </div>
        </>
    );
}

export default IkDocAzk;