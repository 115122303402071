export const xmlStart = ()=>
{
    return ("<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\r\n"+
    "<EXTRWT>\r\n  <WTFORMAT VERSION=\"1.0\">\r\n    <STATEMENTSDOCS>\r\n")
    
}
export const xmlEnd = ()=>
{
    return("    </STATEMENTSDOCS>\r\n  </WTFORMAT>\r\n</EXTRWT>")
}
//блок выписки
export const xmlStatmentBody = (budget,inn,kpp,orgName,accNum)=>
{
    return("<STATEMENTSDOC BUDGETID=\"10000001789\"\r\n        BUDGETNAME=\""+budget+"\" BUDGETYEAR=\"2023\"\r\n        CLOSINGBALANCE=\"1.00\" COMPBUDGETINN=\""+ inn + "\"\r\n"+
    "        COMPBUDGETKPP=\""+ kpp + "\"\r\n        COMPBUDGETNAME=\""+ orgName + "\"\r\n        CREDITDOCSNUM=\"0\" CREDITTURNOVER=\"0.00\" DEBETDOCSNUM=\"0\"\r\n"+
    "        DEBETTURNOVER=\"1.00\" DOCUMENTDATE=\"2024-05-24\" DOCUMENTNUMBER=\"1\"\r\n        ESTIMATENAME=\"\"\r\n"+
    "        ESTIMATETYPE=\"9\" LASTSTATEMENTDATE=\"2024-05-22\"\r\n        OPENINGBALANCE=\"1.00\" PAYERACCOUNTNUMBER=\""+ accNum + "\"\r\n"+
    "        PAYERBANKBRANCHCODE=\"\" PAYERBANKBRANCHNAME=\"\" PAYERBIC=\"\"\r\n        PAYERID=\"10000074118\" PAYERINN=\""+ inn + "\" PAYERKPP=\""+ kpp + "\"\r\n"+
    "        PAYERNAME=\""+ orgName + "\"\r\n        PAYEROFKACCOUNTNUMBER=\"\" PAYEROFKCODE=\"\" PAYEROFKID=\"\" PAYEROFKINN=\"\"\r\n"+
    "        PAYEROFKKPP=\"\" PAYEROFKNAME=\"\" PAYERUFKACCOUNTNUMBER=\"\" PAYERUFKINN=\"\"\r\n"+
    "        PAYERUFKKPP=\"\" PAYERUFKNAME=\"\" PERSONAL_ACCOUNT_FLAG=\"1\" STATEMENTDATE=\"2024-05-22\">\r\n")
}
export const xmlStatmentEnd = ()=>
{
    return("</STATEMENTSDOC>\r\n")
}
// блок кредитовых документов
export const xmlCreditNull = ()=>
{
    return("<CREDITDOCUMENTS/>\r\n")
}
export const xmlCreditStart = ()=>
{
    return("<CREDITDOCUMENTS>\r\n")
}
export const xmlCreditEnd = ()=>
{
    return("</CREDITDOCUMENTS>\r\n")
}

export const xmlCreditBodu = (sum,innPay,kppPay,namePay,date,nom,accContragent,idContragent,innContagent,kppContagent,nameContagent,ground,kcsr,kvr,kfsr,kadm)=>
{
    //исправить
    return("<CREDITDOCUMENT AMOUNT=\"" + sum + "\" BANK_CHARGEOFF_DATE=\"\"\r\n" +
    "            BANK_RECEIPT_DATE=\"\" BO_DATA=\"\" BO_ID=\"\"\r\n            BUDGRECEIVERINN=\""+innPay+"\" BUDGRECEIVERKPP=\""+kppPay+"\"\r\n"+
    "            BUDGRECEIVERNAME=\""+namePay+"\"\r\n            COMPDOCDATE=\"" + date + "\" COMPDOCNUMBER=\"" + nom + "\" CONTRACT_DATA=\"\"\r\n"+
    "            CONTRAGENTACCOUNTNUMBER=\""+accContragent+"\"\r\n            CONTRAGENTBANKBRANCHCODE=\"\" CONTRAGENTBANKBRANCHNAME=\"\"\r\n"+
    "            CONTRAGENTBIC=\"019205400\" CONTRAGENTID=\""+idContragent+"\"\r\n            CONTRAGENTINN=\""+innContagent+"\" CONTRAGENTKPP=\""+kppContagent+"\"\r\n"+
    "            CONTRAGENTNAME=\""+nameContagent+"\"\r\n            CONTRAGENTOFKCODE=\"\" CONTRAGENTOFKINN=\"\" CONTRAGENTOFKKPP=\"\"\r\n"+
    "            CONTRAGENTOFKNAME=\"\" CONTRAGENTUFKACCOUNTNUMBER=\"\"\r\n            CONTRAGENTUFKINN=\"\" CONTRAGENTUFKKPP=\"\" CONTRAGENTUFKNAME=\"\"\r\n"+
    "            DOCCLASS=\"74\" DOCCLASSID=\"74\" DOCUMENTDATE=\"" + date + "\"\r\n            DOCUMENTID=\"10150883007\" DOCUMENTNUMBER=\"" + nom + "\" DO_ID=\"\"\r\n"+
    "            GROUND=\"" + ground + "\"\r\n            KCSR=\"" + kcsr + "\" KDE=\"0000000\" KDF=\"00000\" KDR=\"000\" KESR=\"" + kvr + "\"\r\n"+
    "            KFSR=\"" + kfsr + "\" KIF=\"0\" KVR=\"" + kvr + "\" KVSR=\"" + kadm + "\" MONEYLIABILITY_DATA=\"\"\r\n"+
    "            MONEYLIABILITY_ID=\"\" OPERKIND=\"1\" OPERKINDLS=\"\" PAYKIND=\"0\"\r\n"+
    "            PURPOSEFULGRANT_CODE=\"К3292701000001220006\" PURPOSEFULGRANT_ID=\"13000012933\" QUEUEKIND=\"0\"/>\r\n");
}

//блок дебетовых документов
export const xmlDebetNull = ()=>
{
    return("<DEBETDOCUMENTS/>\r\n")
}
export const xmlDebetStart = ()=>
{
    return("<DEBETDOCUMENTS>\r\n")
}
export const xmlDebetEnd = ()=>
{
    return("</DEBETDOCUMENTS>\r\n")
}
export const xmlDebetBodu = (sum,innPay,kppPay,namePay,date,nom,accContragent,idContragent,innContagent,kppContagent,nameContagent,ground,kcsr,kesr,kvr,kfsr,kadm)=>
{
    return("<DEBETDOCUMENT AMOUNT=\"" + sum + "\" BO_DATA=\"\" BO_ID=\"\"\r\n            BUDGRECEIVERINN=\""+ innPay + "\" BUDGRECEIVERKPP=\""+ kppPay + "\"\r\n"+
    "            BUDGRECEIVERNAME=\""+ namePay + "\"\r\n            COMPDOCDATE=\"" + date + "\" COMPDOCNUMBER=\"" + nom + "\" CONTRACT_DATA=\"\"\r\n"+
    "            CONTRAGENTACCOUNTNUMBER=\""+ accContragent + "\"\r\n            CONTRAGENTBANKBRANCHCODE=\"\" CONTRAGENTBANKBRANCHNAME=\"\"\r\n"+
    "            CONTRAGENTBIC=\"024501901\" CONTRAGENTID=\""+ idContragent + "\"\r\n            CONTRAGENTINN=\"" + innContagent + "\" CONTRAGENTKPP=\"" + kppContagent + "\"\r\n"+
    "            CONTRAGENTNAME=\"" + nameContagent + "\" CONTRAGENTOFKCODE=\"\"\r\n            CONTRAGENTOFKINN=\"\" CONTRAGENTOFKKPP=\"\" CONTRAGENTOFKNAME=\"\"\r\n"+
    "            CONTRAGENTUFKACCOUNTNUMBER=\"\" CONTRAGENTUFKINN=\"\"\r\n            CONTRAGENTUFKKPP=\"\" CONTRAGENTUFKNAME=\"\" DOCCLASS=\"11\"\r\n"+
    "            DOCCLASSID=\"11\" DOCUMENTDATE=\"" + date + "\" DOCUMENTID=\"10150927089\"\r\n            DOCUMENTNUMBER=\"" + nom + "\" DO_ID=\"\"\r\n"+
    "            GROUND=\"" + ground + "\"\r\n            KCSR=\""+ kcsr + "\" KDE=\"0000000\" KDF=\"00000\" KDR=\"000\" KESR=\""+ kesr + "\"\r\n"+
    "            KFSR=\""+kfsr+"\" KIF=\"0\" KVR=\"" + kvr + "\" KVSR=\""+ kadm + "\" MONEYLIABILITY_DATA=\"\"\r\n"+
    "            MONEYLIABILITY_ID=\"\" OPERKIND=\"1\" OPERKINDLS=\"\"\r\n            PAYIDENTDOCUMENTDATE=\"\" PAYIDENTDOCUMENTNUMBER=\"\" PAYIDENTGROUND=\"\"\r\n"+
    "            PAYIDENTKODDOHKADMD=\""+kadm+"\" PAYIDENTKODDOHKD=\"01030100020001810\" PAYIDENTKODDOHKESD=\"\"\r\n"+
    "            PAYIDENTOKATO=\"\" PAYIDENTPERIOD=\"\" PAYIDENTSTATUS=\"0\"\r\n"+
    "            PAYIDENTTYPE=\"1\" PAYKIND=\"0\" PURPOSEFULGRANT_CODE=\"0\"\r\n            PURPOSEFULGRANT_ID=\"13000012933\" QUEUEKIND=\"3\"/>\r\n");
}

//export default {xmlHead,xmlFuter}