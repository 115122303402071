import './navbar.css'
import { Link } from "react-router-dom";
//import {useNavigate } from 'react-router-dom'
//import { HOME_ROUTE,TRAINING_ROUTE,PROJECT_ROUTE,ABOUT_ROUTE } from '../../utils/const'


const Navbar = () => {
    //const navigate = useNavigate ()

    return (<div className='navbar'>
        <div className='box'>
            <div class="dropdown">
                <button class="dropbtn">Загрузка в Барс
                    <i class="fa fa-caret-down"></i>
                </button>
                <div class="dropdown-content">
                    <Link to={"inputDocBars"}>Входящие документы</Link>
                    <Link to={"outputDocBars"}>Исходящие документы</Link>
                    <Link to={"ikDocBars"}>Обработка IK файла</Link>
                </div>
            </div>
            <div class="dropdown">
                <button class="dropbtn">Загрузка в АЦК
                    <i class="fa fa-caret-down"></i>
                </button>
                <div class="dropdown-content">
                    <Link to={"ikDocAzk"}>Обработка IK файла</Link>
                </div>
            </div>
            <div class="dropdown">
                <button class="dropbtn">Обработка выписки
                    <i class="fa fa-caret-down"></i>
                </button>
                <div class="dropdown-content">
                    <Link to={"printStatement"}>Обработка печатной формы</Link>
                </div>
            </div>
        </div>



    </div>)
}
export default Navbar;