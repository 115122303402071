import './footer.css'

const Footer = () => {
    return (
        <footer>
            <div className='box'>
                <p>Разработано для Министерства финансов Республики Татарстан</p>
                <p>Разработчик  Садыков Руслан Разимович, почта s.r.r@tatar.ru</p>
                <p>2024 г.</p>
            </div>
        </footer>
    )
}
export default Footer;