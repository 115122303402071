import JSZip from 'jszip';
import {rtfToTxt} from '../../node_modules/rtf-converter/rtf_converter';
import { saveAs } from 'file-saver';
import * as XLSX from "xlsx/xlsx.mjs";
import * as xmlBars from './xmlBars';


const encodeCP1251 = (string) => {
  function encodeChar(c) {
    let isKyr = function (str) {
      return /[а-я]/i.test(str);
    };
    let cp1251 =
      "ЂЃ‚ѓ„…†‡€‰Љ‹ЊЌЋЏђ‘’“”•–—�™љ›њќћџ ЎўЈ¤Ґ¦§Ё©Є«¬*®Ї°±Ііґµ¶·\
ё№є»јЅѕїАБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдежзийклмнопрстуфхцчшщъыьэюя";
    let p = isKyr(c) ? cp1251.indexOf(c) + 128 : c.charCodeAt(0);
    let h = p.toString(16);
    if (h == "a") {
      h = "0A";
    }
    return "%" + h;
  }
  let res = "";
  for (let i = 0; i < string.length; i++) {
    res += encodeChar(string.charAt(i)); //ну или string[i]
  }
  return res;
};
const toStringIkz = (obj) => {
  return "ZRST|1|10|" + obj.kbk + "|||" + obj.kc + "||" + obj.sum + "|0.00|||";
};

export const mfrtIkToBars = async (fileName)=>
{
    const zip = new JSZip();
    const zipFile = await zip.loadAsync(fileName);
    const extractedFiles = [];
    zipFile.forEach((relativePath, zipEntry) => {
      extractedFiles.push({
        name: zipEntry.name,
        content: zipEntry.async("blob")
      });
    });
    let fileInZip = await extractedFiles[0].content;
    let reader = new FileReader();
    reader.readAsText(fileInZip, "CP1251");
    reader.onload = function () {
      let arrIKZ = reader.result.split("\n");
      const arrXml = [];
      arrXml.push(xmlBars.xmlStart());
      let countId=0;
      let budget;
      let accL;
      let tempDate
      let innPay;
      let kppPay;
      let namePay;
      for(let i=0;i<arrIKZ.length;++i)
      {        
        const lineArr = arrIKZ[i].split("|")
        if(lineArr[0]==="IK")
        {
          console.log(budget+"if")
          budget=lineArr[10];
          accL=lineArr[6];
          tempDate=lineArr[7];
          innPay=lineArr[2];
          kppPay=lineArr[3];
          namePay=lineArr[9];
        }
        if(lineArr[0]==="ZRST")
        {
          const lineZR = arrIKZ[i-1].split("|")
          if(lineZR[5]!=="1654019570")
            continue;
          //let zr = arrIKZ[i-1].split("|");
          console.log(budget+"if2")
          let nom = lineZR[3];
          //let tempDate = lineBdPl[4];
          let tempSum = lineZR[13];
          let ground = lineZR[20].replace(new RegExp("\"", "g"), "");
          let date = tempDate.substring(6) + "-" + tempDate.substring(3, 5) + "-" + tempDate.substring(0, 2);
          let sum = tempSum.replace(" ", "").replace(",", ".");
          let inn = lineZR[22];
          let kpp = lineZR[23];
          let name = lineZR[21].replace(new RegExp("\"", "g"),"");
          let acc = lineZR[25];          
          let kbk = lineArr[3].replace(".", "");
          let kadm = kbk.substring(0, 3);
          let kfsr = kbk.substring(3, 3+4);
          let kcsr = kbk.substring(7, 7+10);
          let kvr = kbk.substring(17);
          ++countId;
          /*arrXml.push(xmlBars.xmlStatmentBody("бюджет Республики Татарстан",lineBdPl[15],lineBdPl[16],lineBdPl[17],lineBdPl[18]));
          arrXml.push(xmlBars.xmlDebetStart())
          arrXml.push(xmlBars.xmlDebetBodu(sum,lineBdPl[15],lineBdPl[16],lineBdPl[17],date,nom,acc,countId,inn,kpp,name,ground,kcsr,kvr,kfsr,kadm))
          arrXml.push(xmlBars.xmlDebetEnd())
          arrXml.push(xmlBars.xmlCreditNull())
          arrXml.push(xmlBars.xmlStatmentEnd());
          */
          arrXml.push(xmlBars.xmlStatmentBody(budget,innPay,kppPay,namePay,accL))
          arrXml.push(xmlBars.xmlDebetStart())
          arrXml.push(xmlBars.xmlDebetBodu(sum,innPay,kppPay,namePay,date,nom,acc,countId,inn,kpp,name,ground,kcsr,kvr,kvr,kfsr,kadm))
          arrXml.push(xmlBars.xmlDebetEnd())
          arrXml.push(xmlBars.xmlCreditNull())
          arrXml.push(xmlBars.xmlStatmentEnd());
        }
      }
      arrXml.push(xmlBars.xmlEnd())
      let blob = new Blob([arrXml.join("")])
      saveAs(blob,"IK.xml")
    }
}

export const mfrtStatmenRtf = async (fileZip) =>
{
    const zip = new JSZip();
    const zipFile = await zip.loadAsync(fileZip);
    const extractedFiles = [];
    zipFile.forEach((relativePath, zipEntry) => {
      extractedFiles.push({
        name: zipEntry.name,
        content: zipEntry.async('text')
      });
    });
    const newArr = [];
    const arrDate = []
    for (let i =0;i<extractedFiles.length;++i)
    {
      let textFile = rtfToTxt(await extractedFiles[i].content);
      let oldName=extractedFiles[i].name
      let temp = textFile.substring(textFile.indexOf("за “"));
      let datevks = temp.substring(0,temp.indexOf("Казначейский счет"))
      datevks = datevks.replace("“","").replace("”","").replace(".","")
      if(!arrDate.includes(datevks))
       arrDate.push(datevks)
      temp = temp.substring(temp.indexOf("Казначейский счет")+17)
      let accNom=temp.substring(0,20)
      newArr.push({oldName , datevks,accNom})
    }
    for(let i=0;i<arrDate.length;++i)
    {
      const zip = new JSZip();
      for(let j=0;j<newArr.length;++j)
      {        
        if(arrDate[i]===newArr[j].datevks)
        {
          zip.file(newArr[j].accNom+".RTF",extractedFiles[j].content)
        } 
      }
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, arrDate[i]+".zip");
      });
    }
}
export const mfrtExcelToBatsInDoc = async (fileExcelName)=>
{
  let fileOutName = "";
  // Работа с Ексель
  const data = await fileExcelName.arrayBuffer();
  const workbook = XLSX.read(data);
  let excelData = XLSX.utils.sheet_to_json(
    workbook.Sheets[workbook.SheetNames[workbook.SheetNames.length - 1]],
    { header: 1 }
  );
  const arrXml = [];
  let innPay="1654019570";
  let kppPay="165501001";
  let namePay="Министерство финансов Республики Татарстан";
  let innContragent ="1654042748";
  let kppContragent = kppPay;
  let nameContagent="УФК по Республике Татарстан (УФК ПО РЕСПУБЛИКЕ ТАТАРСТАН)";
  arrXml.push(xmlBars.xmlStart());
  arrXml.push(xmlBars.xmlStatmentBody("бюджет Республики Татарстан",innPay,kppPay,namePay,"ЛД007110000-МФРТ"));
  arrXml.push(xmlBars.xmlDebetNull())
  arrXml.push(xmlBars.xmlCreditStart())

  for(let i = 0; i<excelData.length;++i)
  {
    if(excelData[i][0]==="Обработка завершена")
    {
      let sum=String(excelData[i][3]);
      sum=sum.replace(" ", "").replace(",", ".")
      if (!sum.includes(".")) sum += ".00";
      if (sum.indexOf(".") === sum.length - 2) sum += "0";
      let nom =excelData[i][1];
      let tempDate=excelData[i][2];
      let date = tempDate.substring(6) + "-" + tempDate.substring(3, 5) + "-" + tempDate.substring(0, 2);
      fileOutName = date;
      let ground = excelData[i][4].replace(new RegExp("\"", "g"), "");
      let acc = "03211643000000011100";
      let kcsr="0000000000";
      let kvr="000";
      let kfsr="0000";
      let kadm="000";
      if(excelData[i][5])
        kadm=String(excelData[i][5]);
      if(excelData[i][6])
      {
        let kbk=String(excelData[i][6]);
        kbk=kbk.replace(/\./g, "");
        kfsr = kbk.substring(0, 4);
        kcsr = kbk.substring(4, 4+10);
        kvr = kbk.substring(14);
      }
      arrXml.push(xmlBars.xmlCreditBodu(sum,innPay,kppPay,namePay,date,nom,acc,i,innContragent,kppContragent,nameContagent,ground,kcsr,kvr,kfsr,kadm));
    }
  }
  arrXml.push(xmlBars.xmlCreditEnd());
  arrXml.push(xmlBars.xmlStatmentEnd());
  arrXml.push(xmlBars.xmlEnd());
  let blob = new Blob([arrXml.join("")])
  saveAs(blob,"in_"+fileOutName+".xml")
} 
export const mfrtExcelToBatsOutDoc = async (fileExcelName)=>
{
  let fileOutName = "";
  let org1 = "7710168360=771001001=Межрегиональное операционное УФК (Минфин России, л/с 08951000920)";
  let org2 = "1654042748=165501001=Управление Федерального казначейства по Республике Татарстан  (л/с 08111475360)";
  let arrOrg1 = org1.split("=");
  let arrOrg2 = org2.split("=");
  // Работа с Ексель
  const data = await fileExcelName.arrayBuffer();
  const workbook = XLSX.read(data);
  let excelData = XLSX.utils.sheet_to_json(
    workbook.Sheets[workbook.SheetNames[workbook.SheetNames.length - 1]],
    { header: 1 }
  );
  const arrXml = [];
  let innPay="1654019570";
  let kppPay="165501001";
  let namePay="Министерство финансов Республики Татарстан";

  arrXml.push(xmlBars.xmlStart());
  arrXml.push(xmlBars.xmlStatmentBody("бюджет Республики Татарстан",innPay,kppPay,namePay,"ЛД007110000-МФРТ"));
  arrXml.push(xmlBars.xmlDebetStart())

  for(let i = 0; i<excelData.length;++i)
  {
    if(excelData[i][0]==="Обработка завершена")
    {
      let innContragent =arrOrg2[0];
      let kppContragent = arrOrg2[1];
      let nameContagent=arrOrg2[2];
      let sum=String(excelData[i][3]);
      sum=sum.replace(" ", "").replace(",", ".")
      if (!sum.includes(".")) sum += ".00";
      if (sum.indexOf(".") === sum.length - 2) sum += "0";
      let nom =excelData[i][1];
      let tempDate=excelData[i][2];
      let date = tempDate.substring(6) + "-" + tempDate.substring(3, 5) + "-" + tempDate.substring(0, 2);
      fileOutName=date;
      let ground = excelData[i][4].replace(new RegExp("\"", "g"), "");
      let acc = "03211643000000019500";
      let kcsr="0000000000";
      let kvr="000";
      let kfsr="0000";
      let kadm="711";
      let kesr = "000"
      if(excelData[i][5]===arrOrg1[2])
      {
        innContragent =arrOrg1[0];
        kppContragent = arrOrg1[1];
        nameContagent=arrOrg1[2];
      }      
        
      if(excelData[i].length===8)
      {
        innContragent=innPay;
        kppContragent=kppPay;
        nameContagent=namePay;
        acc="03211643000000011100";

        kadm=String(excelData[i][5]);
        let kbk=String(excelData[i][6]);
        kbk=kbk.replace(/\./g, "");
        kfsr = kbk.substring(0, 4);
        kcsr = kbk.substring(4, 4+10);
        kvr = kbk.substring(14);
        kesr=String(excelData[i][7])
        kesr=kesr.replace(/\./g, "");
      }
      arrXml.push(xmlBars.xmlDebetBodu(sum,innPay,kppPay,namePay,date,nom,acc,i,innContragent,kppContragent,nameContagent,ground,kcsr,kesr,kvr,kfsr,kadm));
    }
  }
  arrXml.push(xmlBars.xmlDebetEnd());
  arrXml.push(xmlBars.xmlCreditNull())
  arrXml.push(xmlBars.xmlStatmentEnd());
  arrXml.push(xmlBars.xmlEnd());
  let blob = new Blob([arrXml.join("")])
  saveAs(blob,"out_"+fileOutName+".xml")
}
export const mfrtIkToAzk = async (fileExcelName, fileZipName) => {
  let fileOutName = "";
  // Работа с Ексель
  const data = await fileExcelName.arrayBuffer();
  const workbook = XLSX.read(data);
  let excelData = XLSX.utils.sheet_to_json(
    workbook.Sheets[workbook.SheetNames[workbook.SheetNames.length - 1]],
    { header: 1 }
  );
  const arrZkr = [];
  for (let i = 0; i < excelData.length; ++i) {
    const tempst = String(excelData[i][3]);
    if (tempst.includes("ЗКР")) {
      let tempKc = "";
      if (excelData[i][11])
        tempKc = excelData[i][11].replace("(", "").replace(")", "");
      //Исправление суммы из ексель
      let tempSum = String(excelData[i][10]);
      if (!tempSum.includes(".")) tempSum += ".00";
      if (tempSum.indexOf(".") === tempSum.length - 2) tempSum += "0";
      //создаем массив обьектов с нужной информацией
      arrZkr.push({
        name: excelData[i][3],
        kbk: excelData[i][9],
        sum: tempSum,
        kc: tempKc,
      });
    }
  }
  //Работа с зип архивом
  const zip = new JSZip();
  const zipFile = await zip.loadAsync(fileZipName);
  const extractedFiles = [];
  zipFile.forEach((relativePath, zipEntry) => {
    fileOutName = zipEntry.name;
    extractedFiles.push({
      name: zipEntry.name,
      content: zipEntry.async("blob"),
    });
  });
  let zipContent = await extractedFiles[0].content;
  let reader = new FileReader();
  reader.readAsText(zipContent, "CP1251");
  reader.onload = function () {
    let arrIKZ = reader.result.split("\n");
    // окончательная работа
    let flag = false;
    const arrFile = [];
    for (let i = 0; i < arrIKZ.length; ++i) {
      if (flag) {
        flag = false;
        continue;
      }
      arrFile.push(arrIKZ[i].replace("\r", ""));
      let tempLine = arrIKZ[i].split("|");
      if (tempLine[0] === "ZR") {
        for (let j = 0; j < arrZkr.length; ++j) {
          if (arrZkr[j].name === tempLine[3]) {
            let tempZRST = toStringIkz(arrZkr[j]);
            arrFile.push(tempZRST);
            flag = true;
          }
        }
      }
    }
    let docElement = document.createElement("a");
    let arrFileString = arrFile.join("\n");
    arrFileString = arrFileString.replace("\r", "");
    let arrFileStringCP1251 = encodeCP1251(arrFileString);
    docElement.setAttribute(
      "href",
      "data:text/plain;charset=CP1251," + arrFileStringCP1251
    );
    docElement.setAttribute("download", fileOutName);
    docElement.click();
  };
};