import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
    createBrowserRouter,
    RouterProvider,
    BrowserRouter
  } from "react-router-dom";
import IkDocBars from './pages/ikDocBars';
import InputDocBars from './pages/inputDocBars'
import OutputDocBars from './pages/outputDocBars'
import IkDocAzk from './pages/ikDocAzk'
import PrintStatement from './pages/printStatement'

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement:<App />,
      children:[
        {
            path: "/ikDocBars",
            element: <IkDocBars />,
          },
          {
            path: "/inputDocBars",
            element: <InputDocBars />,
          },
          {
            path: "/outputDocBars",
            element: <OutputDocBars />,
          },
          {
            path: "/ikDocAzk",
            element: <IkDocAzk />,
          },
          {
            path: "/printStatement",
            element: <PrintStatement />,
          },
      ]
    },

  ]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

