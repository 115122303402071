import { useState, } from "react";
import {mfrtExcelToBatsInDoc} from "../utils/mftr"

const InputDocBars = () => {
    
    const [fileName, setFileName] = useState("")
    const handleChange = (event) => {
        let file = event.target.files[0];
        setFileName(file);
    };
    const handleProcessingFile = (event) => { 
        mfrtExcelToBatsInDoc(fileName);
        setFileName("")
    };

    return (
        <>
            <h2>Обработка документов (Барс входящие документы)</h2>
        <div>
            Выберите Excel файл
        </div>
        <div>
            <form>
                <label className="input-file">
                    <span className="input-file-text" type="text">{fileName.name}</span>
                    <input type="file" accept=".xls,.xlsx" onChange={handleChange} />
                    <span className="input-file-btn br-left">Обзор</span>
                </label>
            </form>
        </div>
        <div>
        <button type="submit"  onClick={handleProcessingFile}>Обработать</button>
        </div>
        </>
    );
}

export default InputDocBars;